/*
  Lp Component
/*---------------------------------------*/
import { createApp } from 'vue'
import VueSimulator from '@/vue/lp/VueSimulator.vue'
import UIkit from 'uikit/dist/js/uikit'
/*
  Init 
/*---------------------------------------*/

const init = ():void => {
  /*
    LP
  /*---------------------------------------*/
  if (document.getElementById('RateSimulator')) {
    const app = createApp({
      components: {
        VueSimulator
      }
    })
    .mount('#RateSimulator')
  }

  if (document.getElementById('inquiry')) {
    const urlParams = new URLSearchParams(window.location.search);
    // console.log('urlParams', urlParams);
    const formType: string | null = urlParams.get('form');
    // console.log('formType', formType);
    

    // UIkitのタブコンポーネントを取得
    const tabElement = document.getElementById('inquiry__switcher');
    if (tabElement) {
      switch (formType) {
        case 'try':
          UIkit.switcher(tabElement).show(0);
          break;
        case 'agency':
          UIkit.switcher(tabElement).show(1);
          break;
        default:
          UIkit.switcher(tabElement).show(0);
          break;
      }
    }
    //- .document-boxをクリックした時に、switcherのタブを1にする
    const documentBox = document.querySelector('.document-box');
    if (documentBox) {
      documentBox.addEventListener('click', () => {
        UIkit.switcher(tabElement).show(1);
      });
    }
    // const inquiryBox = document.querySelector('.inquiry-box');
    // if (inquiryBox) {
    //   documentBox.addEventListener('click', () => {
    //     UIkit.switcher(tabElement).show(0);
    //   });
    // }
    
  }
}
document.addEventListener("DOMContentLoaded", init)
